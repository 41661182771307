var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from 'react-query';
import styles from './StartupByIdStepsBlock.module.css';
import { fetchStartupSteps } from '@src/redux/reducers/startupByIdReducer/startupByIdReducer';
import StartupViewStep from './StartupViewStep/StartupViewStep';
import { useAppDispatch } from '@src/redux/store';
import { setActive, setContactContent, setModal } from '@src/redux/modalReducer';
import { useParams } from 'react-router-dom';
function StartupByIdStepsBlock(_a) {
    var _b, _c, _d, _e;
    var contacts = _a.contacts;
    var id_full = useParams().id;
    var id = (_b = id_full === null || id_full === void 0 ? void 0 : id_full.split('-')) === null || _b === void 0 ? void 0 : _b[0];
    var dispatch = useAppDispatch();
    var _f = useQuery(["fetchStartupSteps"], function () { return fetchStartupSteps(id); }), startupSteps = _f.data, isFetching = _f.isFetching;
    var firstIncompleteStepId = (_d = (_c = startupSteps === null || startupSteps === void 0 ? void 0 : startupSteps.stages) === null || _c === void 0 ? void 0 : _c.find(function (step) { return !step.completed; })) === null || _d === void 0 ? void 0 : _d.id;
    var handleContact = function () {
        dispatch(setContactContent(contacts));
        dispatch(setModal("contact-startup-modal"));
        dispatch(setActive(true));
    };
    return (_jsxs("div", __assign({ className: styles.stepsBlock }, { children: [_jsx("ul", __assign({ className: styles.stepsBlock__list }, { children: startupSteps && ((_e = startupSteps === null || startupSteps === void 0 ? void 0 : startupSteps.stages) === null || _e === void 0 ? void 0 : _e.map(function (step, index, array) { return (_jsxs("li", { children: [" ", _jsx(StartupViewStep, { data: step, index: index, isLast: index === array.length - 1, isWeHere: firstIncompleteStepId === (step === null || step === void 0 ? void 0 : step.id) }), " "] }, step.id)); })) })), _jsxs("div", __assign({ className: styles.stepsBlock_info }, { children: [_jsxs("div", __assign({ className: styles.stepsBlock_request }, { children: [_jsx("p", __assign({ className: styles.stepsBlock_request_text }, { children: "\u0417\u0430\u043F\u0440\u043E\u0441 \u043F\u0440\u043E\u0435\u043A\u0442\u0430:" })), _jsxs("div", __assign({ className: styles.stepsBlock_reqeust_money }, { children: [(startupSteps === null || startupSteps === void 0 ? void 0 : startupSteps.budget) ? "".concat(startupSteps === null || startupSteps === void 0 ? void 0 : startupSteps.budget, " USD") : 'Не указан', " "] }))] })), (startupSteps === null || startupSteps === void 0 ? void 0 : startupSteps.short_content) && _jsx("p", __assign({ className: styles.stepsBlock_request_description }, { children: startupSteps === null || startupSteps === void 0 ? void 0 : startupSteps.short_content })), _jsx("button", __assign({ className: styles.stepsBlock_contact_btn, onClick: handleContact }, { children: "\u0421\u0432\u044F\u0437\u0430\u0442\u044C\u0441\u044F" }))] }))] })));
}
export default StartupByIdStepsBlock;
