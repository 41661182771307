// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fe4eyQY2s6xh4r8W9O4c {
    width: 100%;
    min-height: 60px;
    background: #ffffff;
    box-shadow: 0 4px 16px 1px rgb(20 130 197 / 10%);
    border-radius: 24px;
    margin-bottom: 10px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding: 20px;
    position: relative;
    color: #000;
}

.ORS1fPitSnBQylgEoNoS {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 7px;
}

.NEH5YSurKGm6GkO6G1zb {
    border-radius: 50%;
    width: 44px;
    height: 44px;
}

.CZYLfpa4DauQrUJqA4mg {
    font-size: 15px;
    font-weight: 600;
}

.Z3Q58ojUtpGI7gKVwGYF {
    height: 140px;
    width: 100%;
    object-fit: cover;
    margin: 15px 0 10px 0;
}

.J3yXFD_EyPcIQXWWmhg4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.J3yXFD_EyPcIQXWWmhg4 img {
    width: 24px;
    height: 24px;
}

.Z8hU4jQpy3FUmjxS0OlQ {
    display: flex;
    justify-content: start;
    align-items: end;
    gap: 2px;
    cursor: pointer;
}

.RE0MCX6ryWW9ahyTY1NT {
    color: var(--main-Gray-2-color);
}

.FPkPGaoANdcBHduLG17r {
    color: var(--blue-hard);
}`, "",{"version":3,"sources":["webpack://./src/pages/StartupByIdPage/StartupByIdPageRightSet/StartupByIdPageRightSet.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,gDAAgD;IAChD,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".startupRightSet {\n    width: 100%;\n    min-height: 60px;\n    background: #ffffff;\n    box-shadow: 0 4px 16px 1px rgb(20 130 197 / 10%);\n    border-radius: 24px;\n    margin-bottom: 10px;\n    display: flex;\n    gap: 16px;\n    flex-direction: column;\n    padding: 20px;\n    position: relative;\n    color: #000;\n}\n\n.startupRightSet_head {\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    gap: 7px;\n}\n\n.startupRightSet_avatar {\n    border-radius: 50%;\n    width: 44px;\n    height: 44px;\n}\n\n.startupRightSet_title {\n    font-size: 15px;\n    font-weight: 600;\n}\n\n.startupRightSet_cover {\n    height: 140px;\n    width: 100%;\n    object-fit: cover;\n    margin: 15px 0 10px 0;\n}\n\n.startupRightSet_footer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 20px;\n}\n\n.startupRightSet_footer img {\n    width: 24px;\n    height: 24px;\n}\n\n.like {\n    display: flex;\n    justify-content: start;\n    align-items: end;\n    gap: 2px;\n    cursor: pointer;\n}\n\n.spanUnlike {\n    color: var(--main-Gray-2-color);\n}\n\n.spanInlike {\n    color: var(--blue-hard);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"startupRightSet": `fe4eyQY2s6xh4r8W9O4c`,
	"startupRightSet_head": `ORS1fPitSnBQylgEoNoS`,
	"startupRightSet_avatar": `NEH5YSurKGm6GkO6G1zb`,
	"startupRightSet_title": `CZYLfpa4DauQrUJqA4mg`,
	"startupRightSet_cover": `Z3Q58ojUtpGI7gKVwGYF`,
	"startupRightSet_footer": `J3yXFD_EyPcIQXWWmhg4`,
	"like": `Z8hU4jQpy3FUmjxS0OlQ`,
	"spanUnlike": `RE0MCX6ryWW9ahyTY1NT`,
	"spanInlike": `FPkPGaoANdcBHduLG17r`
};
export default ___CSS_LOADER_EXPORT___;
