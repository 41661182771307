var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import style from "./NotFoundPage.module.scss";
import { Link } from "react-router-dom";
import error404 from "../../assets/img/404_bg_image.png";
import main_logo from "../../assets/svg/MAIN_LOGo.svg";
import { Loader } from "../../components/Loader/loader";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch } from "@src/redux/store";
import { isNotFountPageOpen } from "@src/redux/reducers/commonReducer/commonReducer";
function NotFoundPage() {
    var dispatch = useAppDispatch();
    var _a = useState(true), fakeLoading = _a[0], setFakeLoading = _a[1];
    var isPlanshet = useMediaQuery({
        query: "(max-width: 1240px)",
    });
    useEffect(function () {
        dispatch(isNotFountPageOpen({ isOpen: true }));
        return function () {
            dispatch(isNotFountPageOpen({ isOpen: false }));
        };
    }, []);
    setTimeout(function () {
        setFakeLoading(false);
    }, 1000);
    return fakeLoading ? (_jsx(Loader, {})) : (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: style.not_found_page }, { children: [_jsx("img", { src: main_logo, alt: "main_logo_404page", className: style.not_found_page__main_logo }), _jsx("p", __assign({ className: style.not_found_page__text }, { children: "\u0423\u043F\u0441... \u0427\u0442\u043E-\u0442\u043E \u043F\u043E\u0448\u043B\u043E \u043D\u0435 \u0442\u0430\u043A, \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0451 \u0435\u0449\u0451 \u0440\u0430\u0437" })), _jsx("img", { src: error404, alt: "img_error", className: style.not_found_page__bg_img }), _jsx("button", __assign({ className: style.not_found_page__button }, { children: _jsx(Link, __assign({ to: "/" }, { children: _jsx("span", __assign({ className: style.not_found_page__button_text }, { children: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E" })) })) }))] })) }));
}
export default NotFoundPage;
