// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QlHRCYFuzpgxbzBzfqdK {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #e0e0e0;
  min-height: 65px;
}

.QlHRCYFuzpgxbzBzfqdK:first-child {
  padding-top: 0;
  /* margin-top: 10px; */
}

.QlHRCYFuzpgxbzBzfqdK:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.wO9Dgj_gty8ZkPO5bAOb {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  cursor: pointer;
}

.X16b5o2vKaxIAFmUulQz {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4f4f4f;
}

.rJhGGWJ_Tk4XQxGW5s9j {
  width: 44px;
  height: 44px;
  margin-right: 3%;
  border-radius: 100%;
  cursor: pointer;
}

._5Br2BVHSVU1Mn7CdaLU {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border-bottom: 1px solid #e0e0e0; */
}

.QObyuhLKeXAI8TTa8dJh {
  display: flex;
  flex-direction: column;
}

.T2pAAJSaQ5UG2a3Edxdk {
  height: 20px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/CompaniesSearchPage/CompaniesSerchResult/cardCompany/cardCompany.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".cardCompanyBlock {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  border-bottom: 1px solid #e0e0e0;\n  min-height: 65px;\n}\n\n.cardCompanyBlock:first-child {\n  padding-top: 0;\n  /* margin-top: 10px; */\n}\n\n.cardCompanyBlock:last-child {\n  margin-bottom: 0;\n  border-bottom: none;\n}\n\n.cardCompamyTittle {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n  color: #000000;\n  cursor: pointer;\n}\n\n.cardCompanySubtitle {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  color: #4f4f4f;\n}\n\n.imgCompany {\n  width: 44px;\n  height: 44px;\n  margin-right: 3%;\n  border-radius: 100%;\n  cursor: pointer;\n}\n\n.infoWrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  /* border-bottom: 1px solid #e0e0e0; */\n}\n\n.companyNameAndStatus {\n  display: flex;\n  flex-direction: column;\n}\n\n.icon_follow_and_unfollow {\n  height: 20px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardCompanyBlock": `QlHRCYFuzpgxbzBzfqdK`,
	"cardCompamyTittle": `wO9Dgj_gty8ZkPO5bAOb`,
	"cardCompanySubtitle": `X16b5o2vKaxIAFmUulQz`,
	"imgCompany": `rJhGGWJ_Tk4XQxGW5s9j`,
	"infoWrapper": `_5Br2BVHSVU1Mn7CdaLU`,
	"companyNameAndStatus": `QObyuhLKeXAI8TTa8dJh`,
	"icon_follow_and_unfollow": `T2pAAJSaQ5UG2a3Edxdk`
};
export default ___CSS_LOADER_EXPORT___;
