// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u5jXeY03JKwU1Pl2jeJs {
    min-width: 300px;
    max-width: 300px;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    position: sticky;
    top: 70px;
    right: 0;
    z-index: 1;
}

.u5jXeY03JKwU1Pl2jeJs::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
}

.u5jXeY03JKwU1Pl2jeJs::-webkit-scrollbar-thumb {
    display: none;
}

.u5jXeY03JKwU1Pl2jeJs::-webkit-scrollbar-track {
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1240px) {
    .sNRqjO3SJ6UT5oTnU10g {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 18px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Startups/Startups.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;IACT,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,UAAU;IACV,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI;QACI,aAAa;QACb,8BAA8B;QAC9B,mBAAmB;QACnB,kBAAkB;IACtB;AACJ","sourcesContent":[".stickyWrapper {\n    min-width: 300px;\n    max-width: 300px;\n    max-height: 90vh;\n    overflow-y: scroll;\n    overflow-x: hidden;\n    position: sticky;\n    top: 70px;\n    right: 0;\n    z-index: 1;\n}\n\n.stickyWrapper::-webkit-scrollbar {\n    width: 4px;\n    background-color: transparent;\n}\n\n.stickyWrapper::-webkit-scrollbar-thumb {\n    display: none;\n}\n\n.stickyWrapper::-webkit-scrollbar-track {\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n@media screen and (max-width: 1240px) {\n    .page_head {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        margin-right: 18px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stickyWrapper": `u5jXeY03JKwU1Pl2jeJs`,
	"page_head": `sNRqjO3SJ6UT5oTnU10g`
};
export default ___CSS_LOADER_EXPORT___;
